import React, { useState } from "react";
// import logo from '../images/logo-removebg.png'
import logo from '../images/ccLogo.png'
import { Link } from "react-router-dom";

function NavBarCompNew() {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div className="Navbar">
            <div>
                <Link  to="/">
                    <img
                        src={logo}
                        // height='80px'
                        // width='auto'
                        alt="logo"
                        className="navBarLogo"
                    />
                </Link>
            </div>

            <div className={`nav-items ${isOpen && "open"}`}>
                <Link  to="/aboutus">Who Are We</Link>
                <Link  to="/teammembers">Our Team</Link>
                <Link  to="/projects">Projects</Link>
                <div
                    className="dropdown"
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                >
                    <Link  to="#">Service</Link>
                    <div className={`dropdown-menu ${isDropdownOpen && "open"}`}>
                        <Link  href="/webDevelopment">Web Development</Link>
                        <Link  href="/mobileDevelopment">Mobile Development</Link>
                        <Link  href="/productDesign">Product Design</Link>
                        <Link  href="/digitalMarketing">Digital Marketing</Link>
                    </div>
                </div>
                <Link  to="/contactus">Get In Touch</Link>
            </div>
            <div
                className={`nav-toggle ${isOpen && "open"}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="bar"></div>
            </div>
        </div>
    );
}


export default NavBarCompNew;
